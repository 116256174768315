<script lang="ts" setup>
import { useNuxtApp } from '#app';

const props = defineProps<{
  imageUrls: string[];
}>();

const { $device: { isMobile } } = useNuxtApp();

const amountVisible = ref<number>(isMobile ? 2 : 5);
const currentIndex = ref<number>(0);
const transition = ref<string>('transform 0.5s ease');

const urlList = computed<string[]>(() => {
  if (props.imageUrls.length === 0) {
    return [];
  }

  return isMobile ? [
    props.imageUrls[props.imageUrls.length - 1],
    ...props.imageUrls,
    ...props.imageUrls.slice(0, amountVisible.value)
  ] : [
    ...props.imageUrls,
    ...props.imageUrls.slice(0, amountVisible.value)
  ];
});

const transform = computed<string>(() => {
  let percentage = (currentIndex.value * 100);

  if (isMobile) {
    percentage += 50;
  }

  return `translate3d(-${percentage}%, 0, 0)`;
});

const infinitSlide = () => {
  setTimeout(() => {
    currentIndex.value++;

    const validateReset = () => {
      return isMobile
        ? currentIndex.value + 1 === urlList.value.length - amountVisible.value
        : currentIndex.value === urlList.value.length - amountVisible.value;
    };

    if (validateReset()) {
      setTimeout(() => {
        transition.value = '';
        currentIndex.value = 0;
        setTimeout(() => transition.value = 'transform 0.5s ease', 500);
      }, 1000);
    }

    infinitSlide();
  }, 2000);
};

onMounted(infinitSlide);
</script>

<template>
  <div class="carousel">
    <div
      v-for="(item, index) in urlList"
      :key="index"
      class="slide"
      :style="{
        transition: transition,
        transform: transform
      }"
      data-test="slide"
    >
      <img
        :src="item"
        :alt="`slide ${index + 1}`"
        :title="`slide ${index + 1}`"
        data-test="slide-image"
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
body.mobile {
  .carousel {
    --linear-gradient: linear-gradient(to left,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0));

    -webkit-mask-image: var(--linear-gradient);
    mask-image: var(--linear-gradient);

    &>.slide {
      flex: 0 0 50%;
    }
  }
}

.carousel {
  display: flex;
  overflow: hidden;

  .slide {
    flex: 0 0 20%;
    width: 100%;

    img {
      border-radius: 8px;
      object-fit: cover;
      width: 100%;
      padding-inline: 0.25rem;
    }
  }
}
</style>

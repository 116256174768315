import type { Company } from '~/models';

import { useNuxtApp } from '#app';

const useCompanyStore = defineStore('customer-company', () => {
  const { $http } = useNuxtApp();

  const list = (): Promise<Company[] | null> => {
    return $http.company.list();
  };

  return {
    list
  };
});

export { useCompanyStore };

<template>
  <svg
    id="a5b6c211-14a6-43e3-9177-b329f5301e58"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1184.08 363.03"
  >
    <path
      class="app-foodinn__default"
      d="M29.35,263.52v-124H8V102.62H29.35V96.37c0-19.42,5.92-40.8,20.07-54C62.25,30.56,79.36,26.28,94.17,26.28a110,110,0,0,1,26.32,3.29l-2,38.5a53.93,53.93,0,0,0-16.12-2.3C85.94,65.77,79,78.6,79,94.39v8.23h32.25v36.85H79.36v124Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__highlight"
      d="M291.26,181.26c0,58.9-41.79,85.88-84.9,85.88-47.05,0-83.24-30.93-83.24-82.92,0-52.32,34.22-85.55,86.21-85.55C258.35,98.67,291.26,132.56,291.26,181.26ZM174.78,182.9c0,27.64,11.51,48.37,32.9,48.37,19.74,0,31.92-19.41,31.92-48.7,0-23.69-9.22-48-31.92-48C184,134.54,174.78,159.21,174.78,182.9Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__highlight"
      d="M482.09,181.26c0,58.9-41.79,85.88-84.89,85.88-47.05,0-83.25-30.93-83.25-82.92,0-52.32,34.22-85.55,86.21-85.55C449.19,98.67,482.09,132.56,482.09,181.26ZM365.61,182.9c0,27.64,11.52,48.37,32.91,48.37,19.74,0,31.91-19.41,31.91-48.7,0-23.69-9.21-48-31.91-48C374.83,134.54,365.61,159.21,365.61,182.9Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__default"
      d="M670,29.9V215.48c0,18.09.65,37.18,1.31,48H626.86l-2-23.36h-1c-9.87,17.76-29.61,27-50.34,27-38.17,0-68.77-32.58-68.77-82.59-.33-54.29,33.56-85.88,72.06-85.88,20.07,0,35.21,7.24,42.44,18.1H620V29.9ZM620,170.73a44.85,44.85,0,0,0-.66-9.21c-3-13.17-13.82-24.35-29.28-24.35-23,0-34.55,20.4-34.55,45.73,0,27.31,13.49,44.42,34.22,44.42a29.64,29.64,0,0,0,29.29-24,65.94,65.94,0,0,0,1-11.51Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__default"
      d="M761.76,57.87c0,13.82-10.2,25-27,25-15.79,0-26-11.19-26-25,0-14.15,10.53-25,26.65-25S761.43,43.72,761.76,57.87ZM710.1,263.52V102.62h50v160.9Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__default"
      d="M800.26,154c0-20.07-.66-36.85-1.32-51.33h43.43L844.68,125h1c6.58-10.53,22.71-26,49.69-26,32.9,0,57.58,22,57.58,69.43v95.09h-50V174.35c0-20.73-7.24-34.88-25.34-34.88-13.82,0-22.05,9.54-25.67,18.76-1.31,3.29-1.64,7.89-1.64,12.5v92.79h-50Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__default"
      d="M993.07,154c0-20.07-.66-36.85-1.32-51.33h43.44l2.3,22.37h1c6.58-10.53,22.7-26,49.68-26,32.91,0,57.58,22,57.58,69.43v95.09h-50V174.35c0-20.73-7.24-34.88-25.34-34.88-13.82,0-22,9.54-25.66,18.76-1.32,3.29-1.65,7.89-1.65,12.5v92.79h-50Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__highlight app-foodinn__smile"
      d="M427.48,290.34a136.16,136.16,0,0,1-244.46,0,183.16,183.16,0,0,0,244.46,0Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M169.11,6.75a78.4,78.4,0,0,1,13.63-1c6.52,0,11.24,1.38,14.35,4a13.78,13.78,0,0,1,4.79,10.8c0,4.64-1.45,8.42-4.13,11-3.63,3.55-9.14,5.29-15.45,5.29a19.59,19.59,0,0,1-4.35-.36V55h-8.84ZM178,29.44a15,15,0,0,0,4.35.44c6.67,0,10.73-3.34,10.73-9,0-5.44-3.77-8.27-9.93-8.27a24.49,24.49,0,0,0-5.15.44Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M241.76,37c0,13-9.14,18.78-18.13,18.78-9.93,0-17.69-6.81-17.69-18.2,0-11.6,7.61-18.7,18.27-18.7C234.72,18.86,241.76,26.25,241.76,37Zm-26.61.37c0,6.81,3.41,12,8.77,12,5.08,0,8.63-4.93,8.63-12.11,0-5.51-2.47-11.89-8.56-11.89C217.69,25.31,215.15,31.47,215.15,37.35Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M254.59,19.65l3.62,16c.87,3.77,1.67,7.61,2.32,11.45h.15c.72-3.84,1.88-7.75,2.83-11.38l4.64-16.1h7.25l4.42,15.67c1.09,4.13,2,8,2.83,11.81h.14c.51-3.84,1.38-7.68,2.32-11.81L289,19.65h8.77L286.71,55h-8.27l-4.2-14.35a108.17,108.17,0,0,1-2.69-11.31h-.14a111,111,0,0,1-2.68,11.31L264.23,55h-8.34L245.45,19.65Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M309.62,39.81c.22,6.38,5.15,9.14,10.87,9.14a28.45,28.45,0,0,0,9.79-1.6l1.31,6.09a31.64,31.64,0,0,1-12.33,2.25c-11.45,0-18.2-7-18.2-17.84,0-9.79,5.95-19,17.26-19S333.55,28.28,333.55,36a22.47,22.47,0,0,1-.29,3.77Zm15.52-6.23c.07-3.27-1.38-8.63-7.33-8.63-5.51,0-7.83,5-8.19,8.63Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M341.45,31c0-4.79-.08-8.27-.29-11.39h7.68l.37,6.67h.21c1.74-4.93,5.88-7.46,9.72-7.46a9.63,9.63,0,0,1,2.1.21v8.34a14.71,14.71,0,0,0-2.68-.22,7.86,7.86,0,0,0-8,6.75,16,16,0,0,0-.21,2.68V55h-8.92Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M373.64,39.81c.22,6.38,5.15,9.14,10.87,9.14a28.45,28.45,0,0,0,9.79-1.6l1.31,6.09a31.64,31.64,0,0,1-12.33,2.25c-11.45,0-18.2-7-18.2-17.84,0-9.79,5.95-19,17.26-19S397.56,28.28,397.56,36a21.44,21.44,0,0,1-.29,3.77Zm15.51-6.23c.08-3.27-1.37-8.63-7.32-8.63-5.51,0-7.83,5-8.19,8.63Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M438,3.49V45c0,3.63.15,7.54.29,9.93h-7.9L430,49.38h-.14a12.66,12.66,0,0,1-11.46,6.38c-8.48,0-15.15-7.18-15.15-18-.07-11.82,7.32-18.85,15.8-18.85,4.86,0,8.34,1.95,9.94,4.56h.14V3.49ZM429.1,34.37a13.87,13.87,0,0,0-.21-2.39,7.83,7.83,0,0,0-7.69-6.24c-5.73,0-8.92,5.08-8.92,11.61s3.19,11.23,8.85,11.23a7.86,7.86,0,0,0,7.68-6.3,9.85,9.85,0,0,0,.29-2.61Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M464.05,3.49H473v21h.14c2.18-3.41,6-5.65,11.31-5.65,8.63,0,14.79,7.17,14.72,17.91,0,12.68-8.05,19-16,19-4.57,0-8.63-1.74-11.17-6.09h-.14L471.37,55h-7.61c.14-2.39.29-6.3.29-9.93ZM473,40.39a10.1,10.1,0,0,0,.21,2.1,8.15,8.15,0,0,0,7.91,6.24c5.58,0,9-4.42,9-11.6,0-6.24-3-11.31-8.92-11.31a8.28,8.28,0,0,0-7.9,6.52,10.41,10.41,0,0,0-.29,2.32Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__info"
      d="M512.26,19.65l6.31,18.93c.72,2.17,1.52,4.86,2,6.81h.22c.5-2,1.16-4.64,1.88-6.88l5.51-18.86h9.5l-8.85,24.08c-4.85,13.19-8.12,19.06-12.25,22.69a18.84,18.84,0,0,1-9.35,4.49l-2-7.46a16.56,16.56,0,0,0,10.08-8.05,3.33,3.33,0,0,0,.5-1.6,4.48,4.48,0,0,0-.43-1.74l-13-32.41Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__default"
      d="M1167,117.93a25,25,0,1,1,25-25A25.06,25.06,0,0,1,1167,117.93Zm0-47.36a22.34,22.34,0,1,0,22.34,22.34A22.36,22.36,0,0,0,1167,70.57Z"
      transform="translate(-7.96 -3.49)"
    />
    <path
      class="app-foodinn__default"
      d="M1157.84,80.63a49.45,49.45,0,0,1,7.94-.58c4.15,0,6.92.62,8.89,2.07a6.28,6.28,0,0,1,2.48,5.21c0,3.33-2.52,5.59-5.26,6.44v.11c2.09.69,3.33,2.49,4,5a61.7,61.7,0,0,0,2.26,7.2h-5.38a30.34,30.34,0,0,1-1.92-6.09c-.86-3.37-2.18-4.44-5.17-4.52h-2.65v10.61h-5.21ZM1163.05,92h3.12c3.54,0,5.72-1.68,5.72-4.25,0-2.79-2.18-4.13-5.6-4.13a16.32,16.32,0,0,0-3.24.23Z"
      transform="translate(-7.96 -3.49)"
    />
  </svg>
</template>

<style lang="scss" scoped>
.app-foodinn {
  &__default {
    fill: #fff;
  }

  &__highlight {
    fill: #e30613;
  }

  &__smile {
    fill-rule: evenodd;
  }

  &__info {
    fill: #878787;
  }
}
</style>

<script lang="ts" setup>
import type { DayTime, WeekTimes } from '~/models';

const props = defineProps<{
  title?: string;
  times: WeekTimes;
}>();

interface DayInfo {
  label: string;
  value: string;
}

const days = computed<DayInfo[]>(() => {
  const getValue = (dayTime: DayTime): string => {
    if (dayTime.start === '00:00' && dayTime.end === dayTime.start) {
      return 'Closed';
    }

    return `${dayTime.start} - ${dayTime.end}`;
  };

  return Object.entries(props.times).map<DayInfo>(([name, dayTime]) => ({
    label: name.charAt(0).toUpperCase() + name.slice(1),
    value: getValue(dayTime)
  }));
});
</script>

<template>
  <div class="week-hours">
    <h2
      v-if="props.title"
      class="week-hours__title text-center lg:text-left"
      data-test="title"
    >
      {{ props.title }}
    </h2>
    <template
      v-for="(day, index) of days"
      :key="index"
    >
      <h3
        class="week-hours__label"
        data-test="label"
      >
        {{ day.label }}
      </h3>
      <h3
        class="text-end lg:text-left"
        data-test="value"
      >
        {{ day.value }}
      </h3>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.week-hours {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  height: fit-content;
  row-gap: 0.5rem;
  column-gap: 5rem;
  grid-template-areas:
    'title title'
    'label value';

  &__title {
    font-size: 20pt;
    padding-bottom: 1rem;
    grid-area: title;
  }
}
</style>
